export const locations = [
  {
    name: "Rome",
  },
  {
    name: "Milan",
  },
  {
    name: "Florence",
  },
  {
    name: "Reggio Calabria",
  },
  {
    name: "Turin",
  },
  {
    name: "Catania",
  },
  {
    name: "Palermo",
  },
  {
    name: "Venice",
  },
  {
    name: "Bari",
  },
  {
    name: "Naples",
  },
  {
    name: "Genoa",
  },
  {
    name: "Bologna",
  },
  {
    name: "Siena",
  },
  {
    name: "Bolzano",
  },
  {
    name: "Trieste",
  },
  {
    name: "Verona",
  },
  {
    name: "Trento",
  },
  {
    name: "Bergamo",
  },
  {
    name: "Siracusa",
  },
  {
    name: "Varese",
  },
  {
    name: "Brescia",
  },
  {
    name: "Salerno",
  },
  {
    name: "Taranto",
  },
];
